.messages{
    margin-top: 27px;
    height: 250px;
    width: 100%;
    max-width: 333px;
    border: 1px solid grey;
    box-sizing: border-box;
    padding: 5px;
    overflow-y: auto;
    overflow-x: scroll;
    word-wrap: break-word;
    font-family: sans-serif;
    white-space: pre-line;
}

.msg-right{
    margin-left: auto;
    margin-right: 0px;
    display: flex;
    text-align: end;
    flex-direction: column;
}
.msg-left{
    margin-left: 0px;
    margin-right: auto;
    display: flex;
    text-align: start;
    flex-direction: column;
}

.chatbox{
    display: grid;
}

.messages input{
    position: fixed;
    height: inherit;
   box-sizing: border-box;
}
