.lobby {
  margin-top: 50px;
}

.container {
  text-align: center;
}

.buttons-container {
  justify-content: space-evenly;
  display: flex;
}

#current-games, #games-lobby{
  width: 60%;
  margin-top: 30px;
  margin-bottom: 100px;
  display: inline-table;
}

#games-lobby, #games-lobby td, #current-games td {
  border: 1px solid rgb(242, 145, 0);
}
#current-games thead , #games-lobby thead{
  background-color: rgb(242, 145, 0);
  color: #fff;
}

h2 {
  padding-bottom: 3%; 
  font-family: 'Open Sans', sans-serif;
  color: #555;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 50px;
  font-weight: 800;
  letter-spacing: -3px;
  line-height: 1;
  text-shadow: #EDEDED 3px 2px 0;
  position: relative;
}

footer{
  background-color: rgb(242, 145, 0);
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 30px;
  text-align: center;
  color: white;
}