.square{
    background: #ffffff;
    float: left;
    font-size: 28px;
    font-weight: normal;
    height: 40px;
    width: 40px;
    margin-right: -1px;
    /* margin-top: -1px;  */
    text-align: center;
    border: none;
    border-radius: 2px;
    background-color: #9ff5b6;
    color: darkgreen;
}


.small-board .square{
    font-size: 18px;
    height: 27px;
    width: 29px;
}

.square:hover{
    background-color: #57fc96;
}



/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
    .square{
        height: 36px;
        font-size: 23px;
        white-space: nowrap;
        padding: 0;
        width: 37px;
    }
}

/* Ordissimo tablet landscape */
@media only screen and (min-width: 1120px) and (max-width: 1120px){
    .square{
        height: 29px;
        font-size: 18px;
        white-space: nowrap;
        padding: 0;
        width: 29px;
    }
}
