.controls-buttons{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.controls p{
    font-size: 25px;   
}

.controls-buttons{
    min-width: 333px;
    min-height: 38px;
 }

 .controls-buttons button{
    margin-right: 5px;
}

.controls p{
    text-align: center;
}

.controls {
    grid-column: 2;
    grid-row: 9;
}

/* 
  ##Device = Laptops, Desktops, celia
  ##Screen = B/w 1025px to 1280px
*/

/* @media (min-width: 1025px) and (max-width: 1280px) {
    th, td{
        width: 25px;
    }
} */