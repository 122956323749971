th, td{
    width: 30px;
}

.game-board{
    display: grid;
    justify-content: center;
}

.game-board table{
    grid-column: 1;
    grid-row: 1/15;
    margin-right: 25px;
    border-collapse: collapse;
}

.player-letters{
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;
    border-radius: 5px;
    grid-column: 2;
    grid-row: 8;
}

.chat {
    grid-row: 1;
    grid-column: 2;
}

.players-scores{
    display: flex;
    justify-content: space-between;
    max-height: 22px;
    font-size: 16px;
    grid-column: 2;
    grid-row: 6;
}

table{
    margin-bottom: 20px;
}

thead{
    text-align: center;
}

.errors{
    text-align: center;
    color: red;
    margin-bottom: 10px;
    grid-row: 10;
    grid-column: 2;
    font-size: 18px;
}


/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {

    th, td{
        width: 25px;
    }
}