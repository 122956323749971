.informations{
    font-size: 15px;
    height: max-content;
    /* background-color: rgb(243, 243, 194); */
    border: 1px solid rgb(242, 145, 0);
    border-radius: 5px;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
}

.informations li{
    list-style-type: circle;
}

h4{
    margin-bottom: 10px;
}

.box{
    /* float: left; */
  height: 15px;
  width: 20px;
  display: inline-block;
  /* margin-bottom: 15px; */
  /* border: 1px solid black; */
  /* clear: both; */
}



.lblue{
    background-color: rgb(52, 174, 235);
}
.dblue{
    background-color: rgb(26, 82, 135);
}
.yellow{
    background-color: rgb(191, 154, 33)
}
.red{
    background-color: rgb(191, 54, 33)
}
/* tablet landscape */
@media (min-width: 1120px) and (max-width: 1120px){
    .informations{
        font-size: 13px;
    }
}