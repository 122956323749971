.helper-left{
    /* font-size: 1px; */
    justify-content: center;
    width: 100%;
    height: max-content;
    border-radius: 5px;
    margin-bottom: 15px;
}

.helper-left table{
    margin: auto;
    text-align: center;
}

.helper-left thead{
    background-color: rgb(242, 145, 0);
  color: #fff;
}

.helper-left table  th{
    border-left: 1px solid rgb(242, 145, 0);
    border-right: 1px solid rgb(242, 145, 0);
}

.helper-left table td{
    border-left: 1px solid rgb(242, 145, 0);
    border-right: 1px solid rgb(242, 145, 0);
    border-bottom: 1px solid rgb(242, 145, 0);
}

.helper-left table tr{
    border: none;
}

@media (min-width: 1120px) and (max-width: 1120px){
    .helper-left{
        font-size: 13px;
    }
}